<template>
  <Layout>
    <div class="main-kefu">
      <div class="base-width">
        <div class="md-kefu-1 flex-middle">
          <!-- 面包屑 -->
          <BreadCrumb :list='routes' :icon='false' />
          <p class="back flex-middle color-blue s16 font-light pointer" @click="$router.back()">
            <span>返回</span>
            <img src="@/assets/images/icon-back.png" alt="">
          </p>
        </div>
        <!--  -->
        <Kefu />
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        {
          name: '首页',
          link: '/'
        }, {
          name: '客服',
          link: '/kefus'
        }
      ],
    };
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.main-kefu{
  padding-bottom: .44rem;
}
.md-kefu-1{
  justify-content: space-between;
  .back{
    img{
      margin-left: .06rem;
    }
    &:hover{
      text-decoration: underline;
    }
  }
}
</style>